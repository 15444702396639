<!--
 * @Author: lbh
 * @Date: 2022-12-24 10:45:53
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 16:00:58
 * @Description: QR Code點餐_圖文4
-->
<template>
  <div
    class="qrCodeGraphicText4-box"
    :class="`edit_${Xindex}_box qrCodeGraphicText4-box__${configs.type} px-qrCodeGraphicText4-box__${configs.type}`"
    @click="clickItem('box')"
  >
    <!-- 標題 -->
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <!-- 橫杠 -->
    <h2
      v-if="configs[`${nowL}subTitle`] || configs.subTitle"
      class="sub-title"
      v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
    ></h2>
    <div
      v-else
      class="line"
    ></div>

    <!-- 子集 -->
    <div
      class="items"
      :class="{textCenter:configs.textCenter}"
    >
      <div
        class="item"
        v-for="(item,index) in configs.items"
        :key="index"
        :style="` background: linear-gradient(180deg,${configs.itemsBgL} 0%,${configs.itemsBgR} 100%);`"
      >
        <img
          class="icon"
          :src="item.icon"
        />
        <h2
          v-if="configs.type == 1"
          class="m-title"
          v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
        ></h2>
        <div
          class="m-content"
          v-html="$util.rex.getHtml(item[`${nowL}content`] || item.content)"
        ></div>
        <h2
          v-if="configs.type == 2"
          class="m-title"
          v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
        ></h2>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          type: '',
          items: []
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x, t) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x, t);
      }
    },
  }
}
</script>

<style lang="less" scoped>
// pc
@media screen and(min-width:500px) {
  .qrCodeGraphicText4-box__1 {
    display: inline-block;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 600;
      color: #000;
      text-align: center;
    }
    .sub-title {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 6px;
      text-align: center;
    }
    .line {
      width: 60px;
      height: 6px;
      background: #333333;
      margin: 20px auto 40px;
    }
    .items {
      padding: 0 330px;
      display: flex;
      flex-wrap: wrap;
      &.textCenter {
        .item {
          width: calc(33% - 52px);
          padding: 64px 16px 40px;
        }
        .m-content {
          text-align: center;
        }
      }
      .item {
        width: calc(33% - 148px);
        margin-left: 36px;
        padding: 64px 64px 40px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        .icon {
          width: 80px;
        }
        .m-title {
          font-size: 30px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          text-align: center;
          margin: 30px 0 20px;
        }
        .m-content {
          font-size: 20px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          white-space: nowrap;
        }
      }
    }
  }

  .qrCodeGraphicText4-box__2 {
    display: inline-block;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 600;
      color: #000;
      text-align: center;
    }
    .sub-title {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 6px;
      text-align: center;
    }
    .line {
      width: 60px;
      height: 6px;
      background: #333333;
      margin: 20px auto 40px;
    }
    .items {
      padding: 0 324px;
      display: flex;
      flex-wrap: wrap;
      &.textCenter {
        .m-content {
          text-align: center;
        }
      }
      .item {
        width: calc(33% - 52px);
        margin-left: 36px;
        padding: 64px 16px 0;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.06),
          -2px -2px 20px 0px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
        .icon {
          width: 70px;
        }
        .m-title {
          font-size: 30px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          background: var(--themeColor);
          border-radius: 20px 20px 0px 0px;
          padding: 12px 0 11px;
          width: 272px;
          text-align: center;
          margin-top: auto;
        }
        .m-content {
          font-size: 20px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 65px;
          margin-top: 30px;
        }
      }
    }
  }
}

//  大平板
@media screen and(max-width:1000px) {
  .px-qrCodeGraphicText4-box__1,
  .px-qrCodeGraphicText4-box__2 {
    .title {
      font-size: 16px !important;
    }
    .items {
      padding: 0 20px !important;
      .icon {
        width: 34px !important;
      }
      .m-title {
        font-size: 16px !important;
      }
      .m-content {
        font-size: 14px !important;
        white-space: nowrap;
      }
    }
  }
}
// 小 平板
@media screen and(max-width:700px) {
  .px-qrCodeGraphicText4-box__1,
  .px-qrCodeGraphicText4-box__2 {
    .title {
      font-size: 16px !important;
    }
    .sub-title {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
    .items {
      padding: 0 20px !important;
      .icon {
        width: 34px !important;
      }
      .m-title {
        font-size: 14px !important;
      }
      .m-content {
        font-size: 10px !important;
        white-space: nowrap;
      }
    }
  }
}

//  移動
@media screen and(max-width:500px) {
  .px-qrCodeGraphicText4-box__1 {
    padding: 25px 10px 15px;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 600;
      color: #000;
      text-align: center;
    }
    .line {
      width: 20px;
      height: 2px;
      background: #333333;
      margin: 10px auto 20px;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      padding: 0 !important;
      &.textCenter {
        .m-content {
          text-align: center;
        }
        .item {
          padding: 25px 6px;
          width: calc(50% - 17px);
        }
      }
      .item {
        width: calc(50% - 29px);
        margin-left: auto;
        margin-bottom: 10px;
        padding: 25px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        .icon {
          width: 28px;
        }
        .m-title {
          font-size: 15px !important;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-top: 7px;
          text-align: center;
        }
        .m-content {
          font-size: 10px !important;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-top: 13px;
          text-align: center;
          line-height: 14px;
        }
      }
    }
  }
  .px-qrCodeGraphicText4-box__2 {
    padding: 25px 10px 15px;
    .title {
      font-size: 15px !important;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }
    .line {
      width: 20px;
      height: 2px;
      background: #333333;
      margin: 10px auto 20px;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      padding: 0 !important;
      &.textCenter {
        .m-content {
          text-align: center;
        }
        .item {
          width: calc(50% - 10px);
          padding: 25px 2px 0;
        }
      }
      .item {
        width: calc(50% - 29px);
        margin-left: auto;
        margin-bottom: 10px;
        padding: 25px 12px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.06),
          -2px -2px 6px 0px rgba(0, 0, 0, 0.06);
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        .icon {
          width: 28px !important;
        }
        .m-title {
          font-size: 15px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-top: auto;
          background: var(--themeColor);
          width: calc(100% - 66px);
          padding: 5px 0;
          text-align: center;
          border-radius: 10px 10px 0 0;
        }
        .m-content {
          font-size: 10px !important;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-top: 15px;
          text-align: center;
          margin-bottom: 7px;
          line-height: 14px;
        }
      }
    }
  }
}
</style>